import ApiService from "@/core/services/api.service";
import { responseError } from '@/core/mixin/shared'
import querystring from "querystring";

export const BASE_URL = 'api/point';
export const USER_POINT_URL = 'api/point/point';
export const USER_COIN_URL = 'api/point/coin';
export const CONVERT_COIN_URL = 'api/point/convert';
export const MODULE_NAME = 'POINTS';
export const LOADING = `LOADING`;
export const ERROR = `ERROR`;
export const SUCCESS = `SUCCESS`;
export const ITEM = `ITEM`;
export const USER_POINT = `USER_POINT`;
export const USER_COIN = `USER_COIN`;
export const ITEMS = `ITEMS`;
export const CUSTOMIZED_ITEMS = `CUSTOMIZED_ITEMS`;
export const IS_ADMIN = `IS_ADMIN`;
export const IS_CUSTOMIZED = `IS_CUSTOMIZED`;

export const SET_LOADING = `M/LOADING`;
export const SET_ERROR = `M/ERROR`;
export const SET_SUCCESS = `M/SUCCESS`;
export const SET_ITEMS = `M/ITEMS`;
export const SET_CUSTOMIZED_ITEMS = `M/CUSTOMIZED_ITEMS`;
export const SET_IS_ADMIN = `M/IS_ADMIN`;
export const SET_IS_CUSTOMIZED = `M/IS_CUSTOMIZED`;
export const SET_USER_POINT = `M/USER_POINT`;
export const SET_USER_COIN = `M/USER_COIN`;
export const SET_ITEM = `M/ITEM`;
export const RESET_VALUES = `M/RESET_VALUES`;

// ACTIONS
export const GET_ITEMS = `GET_ITEMS`;
export const GET_ITEM = `GET_ITEM`;
export const GET_USER_POINT = `GET_USER_POINT`;
export const GET_USER_COIN = `GET_USER_COIN`;
export const CONVERT_TO_COIN = `CONVERT_TO_COIN`;
export const UPDATE_ITEM = `UPDATE_ITEM`;
export const UPDATE_ALL = `UPDATE_ALL`;
export const DELETE_ITEM = `DELETE_ITEM`;
export const CREATE_ITEM = `CREATE_ITEM`;


const state = {
    items: [],
    user_point: null,
    user_coin: null,
    item: {},
    customized_items: [],
    is_customized: false,
    is_admin: true,
    loading: false,
    error: null,
    success: null,
};

const getters = {
    [LOADING]: (state) => {
        return state.loading;
    },
    [ERROR]: (state) => {
        return state.error;
    },
    [SUCCESS]: (state) => {
        return state.success;
    },
    [ITEMS]: (state) => {
        return state.items;
    },
    [CUSTOMIZED_ITEMS]: (state) => {
        return state.customized_items;
    },
    [IS_ADMIN]: (state) => {
        return state.is_admin;
    },
    [IS_CUSTOMIZED]: (state) => {
        return state.is_customized;
    },
    [ITEM]: (state) => {
        return state.item;
    },
    [USER_POINT]: (state) => {
        return state.user_point;
    },
    [USER_COIN]: (state) => {
        return state.user_coin;
    },
};

const mutations = {
    [SET_LOADING]: (state, payload) => {
        state.loading = payload;
    },
    [SET_ERROR]: (state, payload) => {
        state.error = payload ? responseError(payload) : null;
    },
    [SET_ITEMS]: (state, payload) => {
        state.items = payload.points;
    },
    [SET_CUSTOMIZED_ITEMS]: (state, payload) => {
        state.customized_items = payload.customized_points;
    },
    [SET_IS_ADMIN]: (state, payload) => {
        state.is_admin = payload.is_admin;
    },
    [SET_IS_CUSTOMIZED]: (state, payload) => {
        state.is_customized = payload.is_customized;
    },
    [SET_USER_POINT]: (state, payload) => {
        state.user_point = payload;
    },
    [SET_USER_COIN]: (state, payload) => {
        state.user_coin = payload;
    },
    [SET_SUCCESS]: (state, payload) => {
        state.success = payload;
    },
    [SET_ITEM]: (state, payload) => {
        state.item = payload;
    },
    [RESET_VALUES]: (state) => {
        state.success = null;
        state.error = null;
        state.loading = false;
        state.item = null;
    },
};

const actions = {
    [GET_ITEMS]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let filters = payload.filters;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(`${url}?` + querystring.stringify(filters))
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEMS, response.data);
                context.commit(SET_CUSTOMIZED_ITEMS, response.data);
                context.commit(SET_IS_ADMIN, response.data);
                context.commit(SET_IS_CUSTOMIZED, response.data);
            })
            .catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [GET_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_ITEM, response.data);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [GET_USER_POINT]: (context, payload) => {
        let url = payload.url || USER_POINT_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_USER_POINT, response.data);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [GET_USER_COIN]: (context, payload) => {
        let url = payload.url || USER_COIN_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.get(url)
            .then((response) => {
                context.commit(SET_USER_COIN, response.data);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [CONVERT_TO_COIN]: (context, payload) => {
        let url = payload.url || CONVERT_COIN_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then((response) => {
                context.commit(SET_USER_COIN, response.data.coin);
                context.commit(SET_USER_POINT, response.data.point);
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [CREATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.post(url,contents)
            .then(() => {
                context.commit(SET_ITEM, {});
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [DELETE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        return ApiService.delete(url)
            .then(() => {
                context.commit(SET_ITEM, {});
                context.commit(SET_LOADING, false);
            }).catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    },
    [UPDATE_ITEM]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        ApiService.put(url, contents)
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEM, response.data);
            }).catch((error) => {
            context.commit(SET_LOADING, false);
            context.commit(SET_ERROR, error);
        });
    },
    [UPDATE_ALL]: (context, payload) => {
        let url = payload.url || BASE_URL;
        let contents = payload.contents;
        context.commit(SET_LOADING, true);
        context.commit(SET_ERROR, null);
        ApiService.post(url, contents)
            .then((response) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ITEMS, response.data);
                context.commit(SET_CUSTOMIZED_ITEMS, response.data);
                context.commit(SET_IS_ADMIN, response.data);
                context.commit(SET_IS_CUSTOMIZED, response.data);
            })
            .catch((error) => {
                context.commit(SET_LOADING, false);
                context.commit(SET_ERROR, error);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
