<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ $t('point.gamification') }}</li>
          </ul>
        </nav>
      </div>

      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div v-if="errorMessage" class="uk-alert-danger" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: red">
          <i class="uil-exclamation-triangle"></i> {{ errorMessage }}
        </p>
      </div>

      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="mb-0"><i class="uil-10-plus"></i> {{ $t("point.scoring") }}</h4>
            <div v-if="!isAdmin" id="gift-mode" class="btn-night-mode d-flex justify-content-end align-items-center">
              <span class="mr-1">{{ $t("point.customized") }}</span>
              <span class="btn-night-mode-switch position-relative m-0"
                style="left: auto; right: auto; top: inherit; bottom: inherit; width:auto; height: auto;">
                <label class="uk-switch m-0" for="default-1">
                  <input id="default-1" v-model="setIsCustomized" type="checkbox" />
                  <div class="uk-switch-slider"></div>
                </label>
              </span>
            </div>
          </div>
        </div>
        <form class="uk-child-width-2-2@s uk-grid-small p-4 uk-grid uk-grid-stack" uk-grid="">
          <div v-show="isAdmin || !setIsCustomized">
            <h5>{{ $t("point.default_scoring") }}</h5>
            <div v-for="(point, index) in formattedPoints" :key="index"
              class="form-group list row uk-first-column align-items-center">
              <div class="col-md-1 col-form-label">
                <input :id="'opt_' + point.id" v-model="point.status" :checked="point.status" type="checkbox">
              </div>
              <label :for="'opt_' + point.id" class="col-md-9 col-form-label"> {{ $t(point.key_name) }}</label>
              <div class="col-md-2 col-form-label d-flex align-items-center">
                <input v-model="point.point" :class="{ 'disabled': !isAdmin }" :readonly="!isAdmin" min="0"
                  type="number"><i class="uil-coins"></i>
              </div>
            </div>
          </div>

          <div v-show="(!isAdmin && setIsCustomized)">
            <h5>{{ $t("point.customized_scoring") }}</h5>
            <div v-for="(point, index) in formattedCustomizedPoints" :key="index"
              class="form-group list row uk-first-column align-items-center">
              <div class="col-md-1 col-form-label">
                <input :id="'opt_' + point.id" v-model="point.status" :checked="point.status" type="checkbox">
              </div>
              <label :for="'opt_' + point.id" class="col-md-9 col-form-label"> {{ $t(point.key_name) }}</label>
              <div class="col-md-2 col-form-label d-flex align-items-center">
                <input :disabled="point.point_category_id === 10" v-model="point.point" min="0" type="number"> <i
                  v-if="point.point_category_id === 10" class="uil-info-circle ml-1"
                  uk-tooltip="title: İçerikte belirttiğiniz puan geçerli olur" title="" aria-expanded="false"></i><i
                  class="uil-coins"></i>

              </div>
            </div>
          </div>
        </form>

        <div class="uk-flex uk-flex-right p-4">
          <button class="btn btn-default" type="button" @click="updateAll">{{ $t('general.save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import moment from "moment";
import module, {
  BASE_URL,
  CUSTOMIZED_ITEMS,
  ERROR,
  GET_ITEMS,
  IS_ADMIN,
  IS_CUSTOMIZED,
  ITEMS,
  MODULE_NAME,
  UPDATE_ALL,
} from "@/core/services/store/point.module";

const _MODULE_NAME = MODULE_NAME
export default {
  name: "PointList",
  components: { DataTable },
  data() {
    return {
      sort: null,
      orderBy: null,
      search: null,
      errorMessage: null,
      successMessage: null,
      setIsCustomized: false
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, module);
  },
  computed: {
    isAdmin: {
      get() {
        return store.getters[MODULE_NAME + "/" + IS_ADMIN];
      }
    },
    isCustomized: {
      get() {
        return store.getters[MODULE_NAME + "/" + IS_CUSTOMIZED];
      },
      set(value) {
      },
    },
    points: {
      get() {

        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) {
      },
    },
    customized_points() {
      return store.getters[MODULE_NAME + "/" + CUSTOMIZED_ITEMS];
    },
    formattedCustomizedPoints: {
      get() {
        let results = [];
        let items = this.customized_points;
        if (items === null || undefined == items) return [];
        items.forEach(currentItem => {
          results.push({
            name: currentItem.name,
            point: currentItem.point,
            point_category_id: currentItem.point_category_id,
            id: currentItem.id,
            key_name: currentItem.key_name,
            status: (currentItem.status), // (currentItem.status) ? this.$t('general.active') : this.$t('general.passive'),
            created_at: currentItem.created_at ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm:ss') : '-'
          });
          if (currentItem.is_default == 0) {
            this.setIsCustomized = true
          }
        })

        return results;
      },
      set(value) {
      },
    },
    formattedPoints: {
      get() {
        let results = [];
        let items = this.points;
        if (items === null || undefined == items) return [];

        items.forEach(currentItem => {
          results.push({
            name: currentItem.name,
            point: currentItem.point,
            id: currentItem.id,
            key_name: currentItem.key_name,
            status: (currentItem.status), // (currentItem.status) ? this.$t('general.active') : this.$t('general.passive'),
            created_at: currentItem.created_at ? moment(currentItem.created_at).format('DD/MM/YYYY HH:mm:ss') : '-'
          });
        })

        return results;
      },
      set(value) {
      },
    },
    errors: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
  },
  methods: {
    pointResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
      });
    },
    updateAll() {
      let self = this;
      self.formattedPoints.forEach(point => {
        if (point.point < 0) {
          this.errorMessage = this.$t("validation.required_field").replace(":NAME", this.$t("point.point"));
          $("html, body").animate({ scrollTop: 0 }, "slow");
          return false;
        }
      });

      if (this.isAdmin) {
        self.setIsCustomized = false;
      }

      let formData = {
        points: self.setIsCustomized ? self.formattedCustomizedPoints : self.formattedPoints,
        isCustomized: self.setIsCustomized,
      };

      store.dispatch(MODULE_NAME + "/" + UPDATE_ALL, {
        url: BASE_URL + "/update-all",
        contents: formData,
      })
        .then(() => {

          if (!this.errors) {
            $("html, body").animate({ scrollTop: 0 }, "slow");
            this.successMessage = this.$t("general.successfully_updated");
            //this.$router.push({ name: "company.point.list" });
          }
        });

    },
    resetMessage() {
      this.errorMessage = null;
      this.successMessage = null;
    },
  },
  mounted() {
    this.pointResult();
  },
  watch: {
    isCustomized(value) {
      this.setIsCustomized = value;
    },
    successMessage: function (val) {
      this.debouncedResetMessages();
    },
    errorMessage: function (val) {
      this.debouncedResetMessages();
    },
  },
  created: function () {
    this.debouncedResetMessages = _.debounce(this.resetMessage, 1500);
  }
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.form-group.list input[type=number] {
  padding: 15px 0 15px 10px !important;
}

input[type="number"]:focus {
  border: 1px solid #e2e5ec !important;
}
</style>
